import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "picture" }
const _hoisted_2 = { class: "picture-item upload" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]

import { ref, reactive, onMounted } from 'vue'
import { Uploader, UploaderFileListItem, Notify, Loading } from 'vant'
import { addFile } from '@/components/update'
import { uploadResourece, getUserResrouceApi } from '@/api/common'
import { IResoureceImage } from '../types'


export default /*@__PURE__*/_defineComponent({
  __name: 'pictureTab',
  props: {},
  emits: ['onAdd', 'setCutImageUrl'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit

const showNotifyLoading = ref(false)
const afterRead = (file: UploaderFileListItem) => {
  showNotifyLoading.value = true
  emits('setCutImageUrl', file.content, async (base64: string) => {
    const url = await addFile(base64) as string
    let userId = localStorage.getItem('USER_ID')
    if (!userId) {
      console.error('user id is null')
      return
    }
    uploadResourece({
      user_id: Number(userId),
      url,
    })
    fileList.value.push({
      url
    })
    showNotifyLoading.value = false
  })
}
const fileList = ref<{url: string}[]>([]);

const handleClick = (url: string) => {
  emits('onAdd', url)
}

const getResrouceList = async () => {
  try {
    const { data } = await getUserResrouceApi()
    fileList.value = data.map((t: IResoureceImage) => ({ url: t.src, id: t.id }))
  } catch(err) {
    console.log('getResrouceList error:', err)
  }
}
onMounted(() => {
  getResrouceList()
})

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(Uploader), { "after-read": afterRead })
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fileList.value, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "picture-item",
        key: index,
        onClick: ($event: any) => (handleClick(item.url))
      }, [
        _createElementVNode("img", {
          src: `//plugcustom.com.cn/${item.url}~tplv-z8ialgywaa-w-size:200.image`,
          alt: ""
        }, null, 8, _hoisted_4),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "picture-item--remove" }, null, -1))
      ], 8, _hoisted_3))
    }), 128)),
    _createVNode(_unref(Notify), {
      show: showNotifyLoading.value,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((showNotifyLoading).value = $event)),
      type: "success"
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Loading), {
          color: "#ffffff",
          size: "24"
        }),
        _cache[2] || (_cache[2] = _createElementVNode("span", { style: {"margin-left":"6px"} }, "图片上传中...", -1))
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}
}

})