import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-tab" }
const _hoisted_2 = { class: "image" }

import { ref, reactive } from 'vue'
import BgColor from './bgColor.vue'
import BgImage from './bgImage.vue'
import { getBackgroundApi } from '@/api/common'
import { getIntegrateUrl } from '../utils'


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {},
  emits: ['onAdd'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const onAddImage = (url: string) => {
  emits('onAdd', 'image', url)
}
const onAddColor = (color: string) => {
  emits('onAdd', 'color', color)
}

const data = reactive<{
  color: any[]
  image: any[]
}>({
  color: [],
  image: []
})
const getBackground = async () => {
  try {
    const { data: result } = await getBackgroundApi({
      page: 1,
      limit: 100,
    })
    for (let index = 0; index < result.list.length; index++) {
      const element = result.list[index];
      element.type === 'image' ? data.image.push({
        ...element,
        imgUrl: getIntegrateUrl(element.urlvalue)
      }) : data.color.push(element)
    }
  } catch(err) {
    console.log(err)
  }
}
getBackground()
__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(BgColor, {
      data: data.color,
      onOnAdd: onAddColor
    }, null, 8, ["data"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(BgImage, {
        data: data.image,
        onOnAdd: onAddImage
      }, null, 8, ["data"])
    ])
  ]))
}
}

})