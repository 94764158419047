import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "stickers" }
const _hoisted_2 = { class: "subject" }
const _hoisted_3 = {
  key: 0,
  class: "images"
}

import { ref, reactive } from 'vue'
import { ISubject } from '@/types'
import Subject from './subject.vue'
import Images from './image.vue'
import { getTestData } from '../../_test/subjectData'
import { getStickerCategoryApi, getStickerSearchApi, } from '@/api/common'
import { getIntegrateUrl } from '../utils'
import { IStickerImage, ISubjectItem } from '../../types'

type ITypeValue = 'subject' | 'image'
type IShowType = 'card' | 'row'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {},
  emits: ['onAdd'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit

const type = ref<ITypeValue>('subject')
const showType = ref<IShowType>('card')
const subject = reactive<{ list: ISubjectItem[] }>({
  list: []
})

const selectedSubject = ref<null | ISubjectItem>(null)
const selectedImageList = ref<IStickerImage[]>([])
const getStickerSearch = async (ids: string) => {
  try {
    const { data } = await getStickerSearchApi({
      ids
    })
    selectedImageList.value = data.map(t => ({
      thumbnail: t.urlvalue,
      id: t.id,
    }))
  } catch(err) {
    console.log(err)
  }
}
const handleSubjectSelect = (subject: ISubjectItem) => {
  selectedSubject.value = subject
  showType.value = 'row'
  type.value  ='image'
  getStickerSearch(subject.children)
}
const onAddImage = (url: string) => {
  emits('onAdd', url)
}

const getSticker = async () => {
  try {
    const { data } = await getStickerCategoryApi({
      page: 1,
      limit: 1000,
    })
    subject.list = data.list.map((t: ISubjectItem) => ({
      id: t.id,
      ids: t.children.split(','),
      children: t.children,
      thumbnail: getIntegrateUrl(t.urlvalue)
    }))
  } catch(err) {}
}
getSticker()

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Subject, {
        type: showType.value,
        list: subject.list,
        seleced: selectedSubject.value,
        onOnSubjectClick: handleSubjectSelect
      }, null, 8, ["type", "list", "seleced"])
    ]),
    (type.value === 'image' && selectedImageList.value?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(Images, {
            list: selectedImageList.value,
            onOnAdd: onAddImage
          }, null, 8, ["list"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})