import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "bg-image" }
const _hoisted_2 = { class: "list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]

import { ref, reactive } from 'vue'
import DemoImage from '@/assets/images/bg.png'
import DemoImage2 from '@/assets/images/bg2.webp'


export default /*@__PURE__*/_defineComponent({
  __name: 'bgImage',
  props: {
  data: {
    type: Array,
    default () {
      return []
    }
  }
},
  emits: ['onAdd'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit

const list = ref<any[]>([
  { imgUrl: DemoImage, },
  { imgUrl: DemoImage2, },
])
const handleAdd = (item: any) => {
  emits('onAdd', item.imgUrl)
}

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.data, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "item",
          key: index,
          onClick: ($event: any) => (handleAdd(item))
        }, [
          _createElementVNode("img", {
            src: item.imgUrl,
            alt: ""
          }, null, 8, _hoisted_4)
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}
}

})